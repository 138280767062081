import styled from 'styled-components'
import media from '../utils/breakpoints'
import loginSVG from '../../images/illustrations/big-server.svg'
import dots from '../../images/illustrations/dots.svg'

export const LoginPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  ${media.md} {
    background: url(${loginSVG}) bottom right / contain no-repeat;
    background-size: 80vh;
  }
`
export const LoginFormWrapper = styled.div`
  background: url(${dots}) bottom left / contain no-repeat;
  background-size: 15rem;
`
export const LoginFormBoxOuter = styled.div`
  padding: 2.2rem 2.05rem;
  margin: 0 auto;
  background: url("data:image/svg+xml,%3Csvg width='407' height='497' viewBox='0 0 407 497' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.77295 110.149C34.0496 80.1238 127.853 -12.9539 138.948 4.06031C150.044 21.0745 10.8506 177.205 18.9198 223.243C26.989 269.282 217.623 -5.94804 246.874 15.0695C267.046 31.0829 12.8679 344.344 25.9803 374.369C39.0927 404.395 359.646 63.9591 401 113C442.354 162.041 94.5682 448.431 101.629 476.455C108.689 504.478 370.741 286.979 401 312C431.259 337.021 290.245 455.437 277.133 486.463C266.643 511.284 335.971 476.121 371.946 455.437' stroke='%2376FBDD' stroke-width='3'/%3E%3C/svg%3E%0A")
    center center / cover space;
`
export const LoginFormBox = styled.main`
  padding: 2rem;
  /* min-width: 360px; */
  max-width: 400px;
  margin: 0 auto;
  border: 2px #f3f4f7 solid;
  border-radius: 10px;
  background: #fff;
  a:hover {
    color: var(--sky);
  }
  h3 {
    text-align: center;
    padding-bottom: 1rem;
  }
  label {
    display: block;
    padding: 1rem 0 0rem;
  }
  input {
    display: block;
    width: 100%;
    border: 1px solid #f0f0f1;
    color: var(--navy);
    padding: 0.2rem 1rem;
    border-radius: 5px;
    margin-top: 0.2rem;
    &::placeholder {
      color: #b1b1b1;
    }
  }
  p.forgot {
    font-size: 0.7rem;
    text-align: right;
  }
  button {
    display: block;
    cursor: pointer;
    width: 100%;
    margin: 2rem 0;
    border: none;
    color: var(--cool-white);
    background: var(--blue);
    padding: 0.3rem;
    border-radius: 5px;
    transition: background-color ease 350ms;
    &:hover {
      background-color: var(--navy2);
    }
  }

  div.footer {
    text-align: center;
    font-size: 0.9rem;
    border-top: 2px #f3f4f7 solid;
    padding: 0 1rem;
  }
`

import React from 'react'
import { LoginPageWrapper, LoginFormWrapper } from './login-styles'
import LoginForm from './login-form'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

function Login() {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "brand/logo.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <LoginPageWrapper>
      <LoginFormWrapper>
        <Link to="/">
          <Image
            fixed={data.logo.childImageSharp.fixed}
            style={{
              margin: '0 auto',
              display: 'block',
              marginBottom: '1.5rem'
            }}
            alt="Blue Geek Hosting Logo"
          />
        </Link>
        <LoginForm />
      </LoginFormWrapper>
    </LoginPageWrapper>
  )
}

export default Login

import React from 'react'
import { LoginFormBox, LoginFormBoxOuter } from './login-styles'

function LoginForm() {
  return (
    <LoginFormBoxOuter>
      <LoginFormBox>
        <h3>Sign in to your account</h3>
        <form method="POST" action="https://my.bluegeekhosting.com/dologin.php">
          <label htmlFor="username" id="username-label">
            Email
            <input
              type="username"
              name="username"
              id="username"
              placeholder="Your Email"
              required
            />
          </label>
          <label htmlFor="password" id="password-label">
            Password
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              required
            />
          </label>
          <p className="forgot">
            <a href="https://my.bluegeekhosting.com/index.php/password/reset/begin">
              Forgot Password?
            </a>
          </p>
          <button type="submit">Login</button>
        </form>
        <div className="footer">
          Don’t have an account?{' '}
          <a href="https://my.bluegeekhosting.com/register.php">Sign Up!</a>
        </div>
      </LoginFormBox>
    </LoginFormBoxOuter>
  )
}

export default LoginForm

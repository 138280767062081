import React from 'react'
import LoginPage from '../components/login/login'
import SEO from '../components/seo/seo'

function Login() {
  return (
    <>
      <SEO title="Login" /> <LoginPage />{' '}
    </>
  )
}

export default Login
